<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="service"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h3 font-weight-bold"
            >信任服务 列表</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2 font-weight-bold text-h4"
                v-bind="attrs"
                v-on="on"
              >
                新增服务
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model.number="editedItem.id"
                        label="Id"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.name"
                        label="名称"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.category"
                        label="分类"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.primaryPassword"
                        label="主要密码"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.secondaryPassword"
                        label="第二密码"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.serviceEndpoint"
                        label="服务端点"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  取消
                </v-btn>
                <v-btn color="blue darken-1" v-if="editedIndex == -1" text @click="createService()"> 创建 </v-btn>
                <v-btn color="blue darken-1" v-else text @click="save()"> 储存 </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >是否删除该服务?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >取消</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >确认</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ServiceAccounts from '@/apis/ServiceAccounts'

export default {
  name: 'serviceAccountListView',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: false,
        value: 'id',
        class: 'text-h4 font-weight-bold primary--text',
      },
      {
        text: '名称',
        value: 'name',
        class: 'text-h4 font-weight-bold primary--text',
      },
      {
        text: '分类',
        value: 'category',
        class: 'text-h4 font-weight-bold primary--text',
      },
      {
        text: '服务端点',
        value: 'serviceEndpoint',
        class: 'text-h4 font-weight-bold primary--text',
      },
      {
        text: '操作',
        value: 'actions',
        class: 'text-h4 font-weight-bold primary--text',
        sortable: false,
      },
    ],
    service: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: '',
      category: '',
      primaryPassword: '',
      secondaryPassword: '',
      serviceEndpoint: '',
    },
    defaultItem: {
      id: 0,
      name: '',
      category: '',
      primaryPassword: '',
      secondaryPassword: '',
      serviceEndpoint: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '新增信任服务' : '设置信任服务';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getService();
  },

  methods: {
    async getService() {
      const res = await ServiceAccounts.getServiceAccountsApi();
      if (res.data.result !== null) {
        this.service = res.data.result;
      }
    },

    async createService() {
      await ServiceAccounts.createServiceAccountsApi(this.editedItem);
      this.close();
    },

    async deleteItemConfirm() {
      await ServiceAccounts.deleteServiceAccountsApi(this.editedItem.id);
      this.closeDelete();
    },

    async save() {
      await ServiceAccounts.updateServiceAccountsApi(this.editedItem.id, this.editedItem);
      this.close();
    },

    editItem(item) {
      this.editedIndex = this.service.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.service.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
      this.getService();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
      this.getService();
    },
  },
};
</script>
