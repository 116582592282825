import http from '@/http/axios';
import common from './common';

async function getServiceAccountsApi() {
  const res = await http.get('/api/ServiceAccounts');
  return common.haveSuccess(res);
}

async function createServiceAccountsApi(data) {
  const res = await http.post('/api/ServiceAccounts', data);
  return common.haveSuccess(res);
}

async function updateServiceAccountsApi(id, data) {
  const res = await http.put(`/api/ServiceAccounts/${id}`, data);
  return common.haveSuccess(res);
}

async function deleteServiceAccountsApi(id) {
  const res = await http.delete(`/api/ServiceAccounts/${id}`);
  return common.haveSuccess(res);
}

export default {
  getServiceAccountsApi,
  createServiceAccountsApi,
  updateServiceAccountsApi,
  deleteServiceAccountsApi,
};
